<template>
    <div class="fill-height">
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                justify="center"
                align="center"
                class="fill-height overflow-hidden"
            >
                <v-col
                    v-if="!error"
                    class="text-center"
                >
                    <h1>Авторизация в системе...</h1>
                </v-col>
                <v-alert
                    v-else
                    type="error"
                >
                    {{ error }}
                </v-alert>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'SsoRedirect',
    data () {
        return {
            error: null
        }
    },
    async created () {
        const response = await this.getSsoToken();

        if (!response.success) {
            this.error = response.error;
            return
        }
        this.$store.dispatch('user/setSession', {
            access_token: response.access_token,
            profile: response.profile,
            educont_info: response.educont_info
        });
        await this.$store.dispatch('app/statsCreate', { type: 'ENTER_TO_EDUCATION_PLATFORM' });
        this.$router.push(response.page_to_return);
    },
    methods: {
        async getSsoToken () {
            if (!this.$route.query?.code || !this.$route.query?.state) {
                return {success: false, error: 'Отсутствуют обязательные данные для авторизации!'};
            }
            const response = await this.$store.dispatch('app/ssoLogin', { code: this.$route.query?.code, state: this.$route.query?.state })
            
            if (response.success && typeof response.data === 'object') {
                return {...response.data, success: true};
            }
            if (response.error) {
                console.error(response.error);
                return {success: false, error: response.error};
            }
            return {success: false, error: 'Ошибка авторизации.'};
        }
    }
}
</script>
<style lang="scss" scoped>
    
</style>